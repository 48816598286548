/**
* Generated automatically at built-time (2024-08-16T07:39:44.157Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-vinkyl",templateKey: "sites/88-f7f82e4a-acf0-4b1f-a6ed-5191f75aac19"};